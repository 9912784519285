import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Products/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-Motion Motion Detection Sensors",
  "path": "/Products/IN-Motion/500/User_Interface/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "Der IN-Motion 500 verfügt über eine Mikrowelle und einen passiven Infrarot-Bewegungsmelder. Es ist perfekt für die Outdoor-Überwachung geeignet.",
  "image": "../../P_SearchThumb_MotionDetector.png",
  "social": "/images/Search/P_SearchThumb_MotionDetector.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Motion_white.webp",
  "chapter": "Products"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='IN-Motion 500 Motion Detection Sensors' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='The IN-Motion 500 comes with a microwave and a passive infrared motion sensor. It is the perfectly suited for outdoor detection.' image='/images/Search/P_SearchThumb_MotionDetector.png' twitter='/images/Search/P_SearchThumb_MotionDetector.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Produkte/IN-Motion/500/User_Interface/' locationFR='/fr/Products/IN-Motion/500/User_Interface/' crumbLabel="Interface" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-motion-500",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-motion-500",
        "aria-label": "in motion 500 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-Motion 500`}</h1>
    <blockquote>
      <p parentName="blockquote">{`Diskutieren Sie diesen Artikel im `}<a parentName="p" {...{
          "href": "https://forum.instar.de/c/andere-produkte/7"
        }}>{`INSTAR Forum`}</a></p>
    </blockquote>
    <h2 {...{
      "id": "ip-kamera-webui-einstellungen",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#ip-kamera-webui-einstellungen",
        "aria-label": "ip kamera webui einstellungen permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IP Kamera WebUI Einstellungen`}</h2>
    <p>{`Öffnen Sie nach dem Anschließen des PIR-Sensors an den Signaleingang Ihrer Kamera die Web-Benutzeroberfläche der Kamera. Gehen Sie zu den Alarmeinstellungen und aktivieren Sie den externen Alarmeingang:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/d7ceb/IN-Motion500_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAABCklEQVQY03XPy07CUBSF4b7/O5h4CXFg1EIBhQQLBwotx0ovtqBUWnpzZpwYm0jyG5o48Db4smdrra2MbwZ0myq+4xCES+RIp3VySPu0gd5SEZ0WxnUH3xjjTw1W9i1F4JFHa/KioPhBSZw7Hm3J++sLb1WFZ07Rjg7oNo7RL86YaGpN9q/whc5yNuFJzkgeViTblCRJvlGKoiROtnzsdlRVheO6NLV27fxSpT8YICYGM8viPlySlyV5+Vyvy/P8FyUMQ4IgIIoi1usIKSWaptHr9RFCIEaCqWEwGg6xbZssTcmy7F/KfD6vQyzLwjRNFosFnuexL4rjmM1mU9/9O/vir3LXdUn/CP8EKe5arZawh7kAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91eea4f6770718c1e416ac1d7dd9ec62/e4706/IN-Motion500_14.avif 230w", "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/d1af7/IN-Motion500_14.avif 460w", "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/7f308/IN-Motion500_14.avif 920w", "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/e1c99/IN-Motion500_14.avif 1380w", "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/6f300/IN-Motion500_14.avif 1446w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/91eea4f6770718c1e416ac1d7dd9ec62/a0b58/IN-Motion500_14.webp 230w", "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/bc10c/IN-Motion500_14.webp 460w", "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/966d8/IN-Motion500_14.webp 920w", "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/445df/IN-Motion500_14.webp 1380w", "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/07a88/IN-Motion500_14.webp 1446w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/91eea4f6770718c1e416ac1d7dd9ec62/81c8e/IN-Motion500_14.png 230w", "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/08a84/IN-Motion500_14.png 460w", "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/c0255/IN-Motion500_14.png 920w", "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/b1001/IN-Motion500_14.png 1380w", "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/d7ceb/IN-Motion500_14.png 1446w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/91eea4f6770718c1e416ac1d7dd9ec62/c0255/IN-Motion500_14.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/d7ceb/IN-Motion500_15.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "22.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAA9UlEQVQY0z1QW4qEMBD0/gcQPMPsIfyYBY06iq8dxAcadfGVKPgrtXTL7EdRndBVlYph2zaSJEFZlti2DU3ToOsk2rZF13U8S3lDa41932/WGkop1ny4lQMM0zRhWRaLl2WB53kIgheCIGD4vs/wfJ93hmFA3/fM8zyzmdo2rOuK/neC8Xh8QbiCEyg5ikI8n99s5gqBPM+RZRmqqmJO05RB8ziOrCFTrRWk7GAs84LrunCeJ6fUdY04jlEUxf0NSnFNEh3H8V+ZQI+gVqQj/nm/YYRhyEtkSIlRFPHr6D54vSCEgOM4PLuuyztUdZom5g/oTKZ/LFBoxT37mBMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff86976dad1edca1efa0e93ccc5b1c88/e4706/IN-Motion500_15.avif 230w", "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/d1af7/IN-Motion500_15.avif 460w", "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/7f308/IN-Motion500_15.avif 920w", "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/e1c99/IN-Motion500_15.avif 1380w", "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/6f300/IN-Motion500_15.avif 1446w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ff86976dad1edca1efa0e93ccc5b1c88/a0b58/IN-Motion500_15.webp 230w", "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/bc10c/IN-Motion500_15.webp 460w", "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/966d8/IN-Motion500_15.webp 920w", "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/445df/IN-Motion500_15.webp 1380w", "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/07a88/IN-Motion500_15.webp 1446w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ff86976dad1edca1efa0e93ccc5b1c88/81c8e/IN-Motion500_15.png 230w", "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/08a84/IN-Motion500_15.png 460w", "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/c0255/IN-Motion500_15.png 920w", "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/b1001/IN-Motion500_15.png 1380w", "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/d7ceb/IN-Motion500_15.png 1446w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ff86976dad1edca1efa0e93ccc5b1c88/c0255/IN-Motion500_15.png",
              "alt": "IN-Motion 500",
              "title": "IN-Motion 500",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Abhängig von der Betriebsart des Sensors (NO oder NC) erhalten Sie möglicherweise einen konstanten Alarmauslöser. Passen Sie den Alarmauslösungspegel (niedrig / hoch) oder den Stromkreis während des Alarms (geschlossen / offen) entsprechend an.`}</p>
    <p>{`Wenn Sie den PIR- oder Mikrowellensensor unabhängig voneinander verwenden, wird ein Alarm ausgelöst, wenn die rote oder blaue LED leuchtet. Wenn Sie den PIR- und den Mikrowellensensor kombinieren, wird nur dann ein Alarm ausgelöst, wenn die rote LED leuchtet. (Seite 3 -> LED-Anzeige & Seite 4 -> DIP-Schalter -> DIP 5 -> "ON" / "Off" -Modus).`}</p>
    <p>{`Der Bewegungsmelder löst jetzt ein Alarmereignis in der Kamera aus, wenn die LED leuchtet. Das Triggerereignis wird wie ein internes Bewegungserkennungsereignis der Kamera behandelt. So können alle Einstellungen für ein Alarmereignis (Videoaufzeichnung, E-Mail-Benachrichtigung, FTP-Upload usw.) vom PIR-Sensor ab sofort ausgelöst werden.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      